import React, { Suspense, lazy } from 'react'
import { Routes,Route } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import Loading from '../Shared/Components/Loading'

const HomePage = lazy(()=>import('../pages/home/HomePage'))
const NewProjectPost = lazy(()=>import('../pages/project/pages/NewProjectPost'))
const Project = lazy(()=>import('../pages/project/pages/Project'))

const ProfilePage = lazy(()=>import('../pages/profile/pages/ProfilePage'))


const SignIn = lazy(()=>import('../pages/auth/pages/SignIn'))
const AboutUs = lazy(()=>import('../pages/about/AboutUs'))
const SignUp = lazy(()=>import('../pages/auth/pages/SignUp'))
const ForgotPassword = lazy(()=>import('../pages/auth/pages/ForgotPassword'))
const RestPassword = lazy(()=>import('../pages/auth/pages/RestPassword'))

const GraphyicsDesign = lazy(()=>import('../pages/others/pages/GraphyicsDesign'))
const Photography = lazy(()=>import('../pages/others/pages/Photography'))
const Programming = lazy(()=>import('../pages/others/pages/Programming'))
const VideoEditing = lazy(()=>import('../pages/others/pages/VideoEditing'))

const Advertisement = lazy(()=>import('../pages/others/pages/Advertisement'))
const EducationHomePage = lazy(()=>import('../pages/education/pages/EducationHomePage'))
const Education = lazy(()=>import('../pages/education/pages/Education'))
const BasicInfoPage = lazy(()=>import('../pages/auth/pages/BasicInfoPage'))
const PolicyHomePage = lazy(()=>import('../pages/policy/pages/PolicyHomePage'))

const MainRoute = () => {
  return (
    <Suspense fallback={<Loading/>}>
    <Routes>
        
        <Route path='/' element={<HomePage/>}/>
        <Route element={<PrivateRoute/>}>
           <Route path='/new_project' element={<NewProjectPost/>}/>
            <Route path='/new_project/:id' element={<NewProjectPost/>}/>
           <Route path='/project' element={<Project/>}/>
           <Route path='/profile' element={<ProfilePage/>}/>
           <Route path='/education' element={<EducationHomePage/>}/>
           <Route path='/education/:id' element={<Education/>}/>
          
           
        </Route>
        <Route path='/graphics' element={<GraphyicsDesign/>}/>
        <Route path='/programming' element={<Programming/>}/>
        <Route path='/video_editing' element={<VideoEditing/>}/>
        <Route path='/photography' element={<Photography/>}/>
          <Route path='/basic_info' element={<BasicInfoPage/>}/>
        <Route path='/Advertisement' element={<Advertisement/>}/>
      

        <Route path='/about' element={<AboutUs/>}/>
        <Route path='/policy' element={<PolicyHomePage/>}/>
        <Route path='/signin' element={<SignIn/>}/>
        <Route path='/signup' element={<SignUp/>}/>
        <Route path='/forget_password' element={<ForgotPassword/>}/>
        <Route path='/rest_password' element={<RestPassword/>}/>
    </Routes>
    </Suspense>
  )
}

export default MainRoute