import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import MainRoute from './Route/MainRoute';
import { Toaster } from 'sonner';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { getUserProfileData } from './redux/features/authSlice';
import { getAllProjects } from './redux/features/projectSlice';
const telegramBot=window.Telegram.WebApp

function App() {
  const dispatch=useDispatch()
  const id=JSON.parse(localStorage.getItem('user'))?.userId
  useEffect(()=>{
    telegramBot.ready()
  },[])
  useMemo(()=>{
    dispatch(getUserProfileData({id}))
    dispatch(getAllProjects())
  },[id])

  return (
    <div className="app">
       <Toaster 
        richColors 
        position='top-center'
      />
      <Router>
        <MainRoute/>
      </Router>
    </div>
  );
}

export default App;
