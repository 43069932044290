import { createSlice,createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import { basicUrl } from "../../api/basicUrl";


export const createNewProject=createAsyncThunk('project/createNewProject',async({newProjectData,toast,navigate})=>{
    try {
        await axios.post(`${basicUrl}/new_project`,newProjectData)
        toast.success('your project successfully uploaded') 
        navigate('/') 
        setNewProjectData([])

    } catch (error) {
         console.log(error)
    }
})


export const getAllProjects=createAsyncThunk('project/getAllProjects',async()=>{
    try {
          const response=await axios.get(`${basicUrl}/getAllProjects`)
          return response.data
    } catch (error) {
         console.log(error)
    }
})

export const updateView=createAsyncThunk('project/updateView',async({id})=>{
    try {
          const response=await axios.put(`${basicUrl}/update_view/${id}`)
          return response.data
    } catch (error) {
         console.log(error)
    }
})
export const updateLike=createAsyncThunk('project/updateLike',async({id})=>{
    try {
          const response=await axios.put(`${basicUrl}/update_like/${id}`)
          return response.data
    } catch (error) {
         console.log(error)
    }
})

export const updateProject=createAsyncThunk('project/updateProject',async({newProjectData,toast,navigate})=>{
    try {
          await axios.put(`${basicUrl}/update_project/`,newProjectData)
          toast.success('Project successfully updated')
          navigate('/')
    } catch (error) {
         console.log(error)
    }
})

export const updateUnLike=createAsyncThunk('project/updateUnLike',async({id})=>{
    try {
          const response=await axios.put(`${basicUrl}/un_like/${id}`)
          return response.data
    } catch (error) {
         console.log(error)
    }
})

export const addLike=createAsyncThunk('project/addLike',async({data})=>{
    try {
          const response=await axios.post(`${basicUrl}/add_like`,data)
          return response.data
    } catch (error) {
         console.log(error)
    }
})

export const deleteLike=createAsyncThunk('project/deleteLike',async({data})=>{
    console.log(data);
    try {
          const response=await axios.delete(`${basicUrl}/delete_like`,{data:data})
          return response.data
    } catch (error) {
         console.log(error)
    }
})
export const getAllLikedProjects=createAsyncThunk('project/getAllLikedProjects',async()=>{
    try {
          const response=await axios.post(`${basicUrl}/get_all_liked_projects`)
          return response.data
    } catch (error) {
         console.log(error)
    }
})

export const deleteProject=createAsyncThunk('project/deleteProject',async({id,toast})=>{
    console.log(id);
    try {
          const response=await axios.delete(`${basicUrl}/delete_project/${id}`)
          toast.success(response.data.message)
    } catch (error) {
         console.log(error)
    }
})

const projectSlice=createSlice({
    name:'project',
    initialState:{
        projects:[],
        loading:false,
        userId:'',
        likeId:'',
        viewId:'',
        likedProjects:[],
        filterData:[],
        devProject:[],
        projectData:[],
        isProjectUploaded:false,
        isProjectUpdated:false
    },

    reducers:{
        setProjectId:(state,action)=>{
           state.userId=action.payload
        },
        setLikeId:(state,action)=>{
           state.likeId=action.payload
        },
        setViewId:(state,action)=>{
           state.viewId=action.payload
        },
        setFilteredData:(state,action)=>{
           state.filterData=action.payload.data?.filter(data=>data.userId==action.payload.userId)
        },
        setNewProjectData:(state,action)=>{
            state.projectData=action.payload
        },
        restProjectData:(state,action)=>{
            state.projectData=action.payload
        },
        addInput:(state,action)=>{
          
        state.projectData=state.projectData.map((data)=>
        {
        if(data.projectImage===action.payload){
             
        return {
           ...data,
           isHeaderVisible:!data.isHeaderVisible,
           
        }
        }else return data
       })
    },
      setHeaderInput:(state,action)=>{
       
        state.projectData=state.projectData.map((data)=>
        {
        
        if(data.projectImage===action.payload.id){
        return {
           ...data,
           headerText:action.payload.value
        }
      }else return data
    }
    )
},
    removeProject:(state,action)=>{
        state.projectData=state.projectData.filter(data=>{
         if(data.projectImage!==action.payload){
            return data
        }})
    },
},      
    
    extraReducers:{
        [createNewProject.pending]:(state,action)=>{
            state.isProjectUploaded=true
       },
       [createNewProject.fulfilled]:(state,action)=>{
            state.isProjectUploaded=false
            state.projectData=[]
       },
       [createNewProject.rejected]:(state,action)=>{
        state.isProjectUploaded=true
       },
        [getAllProjects.pending]:(state,action)=>{
            state.loading=true
       },
       [getAllProjects.fulfilled]:(state,action)=>{
            state.loading=false
            state.projects=action.payload
            state.devProject=action.payload
       },
       [getAllProjects.rejected]:(state,action)=>{
        state.loading=true
       },

       [updateLike.pending]:(state,action)=>{
            state.loading=true
       },
       [updateLike.fulfilled]:(state,action)=>{
            state.projects=[...state.projects.map(data=>
             { 
                if(data._id===state.likeId){
                if(data.liked){
                return {...data,
                    likeCount:data.likeCount+1,
                }
               }
              }else return data
            }  
           
                )]
       },
       [updateLike.rejected]:(state,action)=>{
        state.loading=true
       },

       [getAllLikedProjects.pending]:(state,action)=>{
            state.loading=true
       },
       [getAllLikedProjects.fulfilled]:(state,action)=>{
            state.loading=false
            state.likedProjects=action.payload
       },
       [getAllLikedProjects.rejected]:(state,action)=>{
        state.loading=true
       },

       [updateProject.pending]:(state,action)=>{
            state.isProjectUpdated=true
       },
       [updateProject.fulfilled]:(state,action)=>{
            state.isProjectUpdated=false
       },
       [getAllLikedProjects.rejected]:(state,action)=>{
        state.isProjectUpdated=true
       },


    }
})

export const {
setProjectId,
setLikeId,
setViewId,
setFilteredData,
setNewProjectData,
restProjectData,
addInput,
setHeaderInput,
removeProject
}=projectSlice.actions

export const projectReducer=projectSlice.reducer