import { configureStore } from "@reduxjs/toolkit";
import {persistReducer} from 'redux-persist'
import {combineReducers} from '@reduxjs/toolkit'
import storage from "redux-persist/lib/storage"
import { authReducer } from "./features/authSlice";
import { feedbackReducer } from "./features/feedbackSlice";
import { messageReducer } from "./features/messageSlice";
import { projectReducer } from "./features/projectSlice";
import { courseReducer } from "./features/courseSlice";
import localforage from 'localforage';



const persistConfig={
    key:'persistCollection',
    storage: localforage,
}

const reducer = combineReducers({
    auth:authReducer,
    feedback:feedbackReducer,
    message:messageReducer,
    project:projectReducer,
    course:courseReducer
})

const persistedReducer = persistReducer(persistConfig,reducer);
export const store = configureStore({
    reducer:persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    
})