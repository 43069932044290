import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

const PrivateRoute = () => {
    const {isUserLoggedIn}=useSelector(state=>state.auth)
  return (
    isUserLoggedIn?<Outlet/>:<Navigate to={'/signin'}/>
  )
}

export default PrivateRoute