import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { basicUrl } from "../../api/basicUrl";


export const getAllMessages=createAsyncThunk('message/getAllMessages',async()=>{
    try {
          const response = await axios.get(`${basicUrl}/getAllMessages`)
          return response.data
    } catch (error) {
        console.log(error)        
    }
})

export const sendMessage=createAsyncThunk('message/sendMessage',async({newMessageData,toast})=>{
    console.log(newMessageData);
    try {
        await axios.post(`${basicUrl}/send_message`,newMessageData)
        toast.success('Message successfully sent')
    } catch (error) {
        toast.error(error.response.data.message)  
    }
})

const messageSlice=createSlice({
    name:'message',
    initialState:{
        messages:[],
        loading:false,
        messageData:{}
    },
    reducers:{
       setMessageId:(state,action)=>{
        state.messageData=action.payload
       }
    },
    extraReducers:{
        [getAllMessages.pending]:(state,action)=>{
           state.loading=true
        },
        [getAllMessages.fulfilled]:(state,action)=>{
           state.loading=false
           state.messages=action.payload
        },
        [getAllMessages.pending]:(state,action)=>{
           state.loading=false
        },

        [sendMessage.pending]:(state,action)=>{
           state.loading=true
        },
        [sendMessage.fulfilled]:(state,action)=>{
           state.loading=false
        },
        [sendMessage.pending]:(state,action)=>{
           state.loading=false
        }
    }
})

export const {setMessageId}=messageSlice.actions

export const messageReducer=messageSlice.reducer