import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from 'axios';
import { basicUrl } from '../../api/basicUrl';

// axios.interceptors.request.use((req)=>{
//     if(sessionStorage.getItem("user")){
//         req.headers.authorization = `Bearer ${(JSON.parse(sessionStorage.getItem("user")))}`
//     }
//   return req;
// })




export const signUp = createAsyncThunk('auth/signUp',async({userData,navigate,toast})=>{
    try {
    
         const response = await axios.post(`${basicUrl}/signUp`,userData)
         if(response){
            toast.success('your account is successfully created')
            navigate('/basic_info')
            return response.data
         }
    } catch (error) {
         toast.error(error.response.data.message)      
    }
})


export const signIn = createAsyncThunk('auth/signIn',async({userData,navigate,toast})=>{
    try {
         const response = await axios.post(`${basicUrl}/signin`,userData)
         if(response){
            toast.success('well come back')
            navigate('/')
            return response.data
         }
    } catch (error) {
        toast.error(error.response.data.message)    
    }
})


export const getUserInfo = createAsyncThunk('auth/getUserInfo',async()=>{
    try {
         const response = await axios.get(`${basicUrl}/getUserInfo`)
         if(response){
           return response.data
         }
    } catch (error) {
        console.log(error.response.data.error)  
    }
})


export const updateImage = createAsyncThunk('auth/uploadImage',async({newProfileImage,toast})=>{
    try { 
         await axios.put(`${basicUrl}/updateProfileImage`,newProfileImage)
         toast.success('your image is successfully updated')
    } catch (error) {
        console.log(error.response.data.error)  
    }
})

export const updateProfession = createAsyncThunk('auth/updateProfession',async({userData,toast})=>{
   console.log(userData);
    try { 
         await axios.put(`${basicUrl}/update_profession`,userData)
         toast.success('your profession is successfully updated')
    } catch (error) {
        console.log(error.response.data.error)  
    }
})

export const updateSkill = createAsyncThunk('auth/updateSkill',async({userData,toast})=>{
    try { 
         await axios.put(`${basicUrl}/update_skill`,userData)
         toast.success('your skill is successfully updated')
    } catch (error) {
        console.log(error.response.data.error)  
    }
})



export const getUserProfileData = createAsyncThunk('auth/getUserProfileData',async({id})=>{

  try {
         const response = await axios.get(`${basicUrl}/getUserInfo/${id}`)
         if(response){   
            return response.data
         }
    } catch (error) {
        console.log(error.response.data.error)    
    }
})

export const getDeveloperProfileData = createAsyncThunk('auth/getDeveloperProfileData',async({userId})=>{

  try {
         const response = await axios.get(`${basicUrl}/get_developer_info/${userId}`)
         if(response){   
            return response.data
         }
    } catch (error) {
        console.log(error.response.data.error)    
    }
})



export const editUserProfile = createAsyncThunk('auth/editUserProfile',async({newUserData,toast})=>{
  
  try {
         const response = await axios.put(`${basicUrl}/updateProfile`,newUserData)
         if(response){
            toast.success('your profile successfully updated')
            return response.data
         }
    } catch (error) {
        toast.error(error.response.data.message)  
    }
})




export const restPassword = createAsyncThunk('auth/restPassWord',async({newPasswordData,toast})=>{
  
  try {
         const response = await axios.put(`${basicUrl}/restPassword`,newPasswordData)
         if(response){
            toast.success('your password successfully changed')
            return response.data
         }
    } catch (error) {
        toast.error(error.response.data.message)
       
    }
})





export const authSlice= createSlice({
    name:'auth',
    initialState:{
      users:[],
      developerData:[],
      loading:false,
      isUserLoggedIn:false,
      userProfileData:[],
      surveyDetailValue:[],
      isUserExist:false,
      isUserVerified:false,
      myWalletBalance:[],
      uploadImageUrl:{},
      isImageLoading:false,
      isPhoneNumberExist:false,
      navigateTo:'',
      navIdentifier:'',
      userLoginData:{},
      isSignUpVerified:false,
      isAppointmentVerified:false,
      signUpNumber:'',
      basicInfoNav:'',
      skill:[],
      developerInfo:[]
},
  reducers:{
    logOut:(state,action)=>{
      localStorage.removeItem('user')
      state.users=[]
      state.userProfileData=[]
      state.skill=[]
      state.loading=false
      state.isUserLoggedIn=false
      state.modeColor='white'
      state.uploadImageUrl={}
      state.isSignUpVerified=false
      state.isAppointmentVerified=false
    },
     setMode:(state,action)=>{
       state.isLightMode=!state.isLightMode
       if(!state.isLightMode){
         state.modeColor='#1E1E1E'
       }else state.modeColor='white'
    },
    
    setUserSignUpData:(state,action)=>{
       return {...state,userSignUpData:{...state.userSignUpData,"value":action.payload}}
    },

    setNavigateValue:(state,action)=>{
          state.navigateTo=action.payload
    },
    setNavigateIdentifier:(state,action)=>{
          state.navIdentifier=action.payload
    },
    setSignUpVerification:(state,action)=>{
          state.isSignUpVerified=action.payload
    },

     setSignUpNumber:(state,action)=>{
          state.signUpNumber=action.payload
    },
     setBasicInfoNav:(state,action)=>{
          state.basicInfoNav=action.payload
    },
    setSkills:(state,action)=>{
         if(action.payload.length>0){
         return {
          ...state,
          skill: [...state.skill,
            action.payload
          ]
         }
        }else state.skill=[]
    },
    removeSkills:(state,action)=>{
         state.skill=state.skill.filter(data=>data!==action.payload)  
    }
  },
  extraReducers:{
    [signUp.pending]:(state,action)=>{
      state.loading=true
      state.isUserLoggedIn=false
    },
    [signUp.fulfilled]:(state,action)=>{
      state.users=action.payload
      state.loading=false
      state.isUserLoggedIn=true
      localStorage.setItem('user',JSON.stringify(action.payload))
    },
    [signUp.rejected]:(state,action)=>{
         state.loading=true
         state.isUserLoggedIn=false
    },

    [signIn.pending]:(state,action)=>{
      state.loading=true
      state.isUserLoggedIn=false
    },
    [signIn.fulfilled]:(state,action)=>{
      state.users=action.payload
      state.loading=false
      state.isUserLoggedIn=true
      localStorage.setItem('user',JSON.stringify(action.payload))
    },
    [signIn.rejected]:(state,action)=>{
        state.isUserLoggedIn=false
        state.loading=true 
    },

  
    [getUserProfileData.pending]:(state,action)=>{
      state.loading=true
    },
    [getUserProfileData.fulfilled]:(state,action)=>{
      state.userProfileData=[action.payload]
      state.loading=false
    },
    [getUserProfileData.rejected]:(state,action)=>{
         state.loading=true
    },

    [getDeveloperProfileData.pending]:(state,action)=>{
      state.loading=true
    },
    [getDeveloperProfileData.fulfilled]:(state,action)=>{
      state.developerInfo=action.payload
      state.loading=false
    },
    [getDeveloperProfileData.rejected]:(state,action)=>{
         state.loading=true
    },

    [editUserProfile.pending]:(state,action)=>{
      state.loading=true
    },
    [editUserProfile.fulfilled]:(state,action)=>{
      state.userProfileData=[action.payload]
      state.loading=false
    },
    [editUserProfile.rejected]:(state,action)=>{
         state.loading=true
    },

    [updateImage.pending]:(state,action)=>{
      state.loading=true
    },
    [updateImage.fulfilled]:(state,action)=>{
     state.loading=false
      
    },
    [updateImage.rejected]:(state,action)=>{
         state.loading=true
    },

    [updateProfession.pending]:(state,action)=>{
      state.loading=true
    },
    [updateProfession.fulfilled]:(state,action)=>{
     state.loading=false
      
    },
    [updateProfession.rejected]:(state,action)=>{
         state.loading=true
    },

    [updateSkill.pending]:(state,action)=>{
      state.loading=true
    },
    [updateSkill.fulfilled]:(state,action)=>{
     state.loading=false
      
    },
    [updateSkill.rejected]:(state,action)=>{
         state.loading=true
    },


    
  }

})

  export const {
    logOut,
    setMode,
    setUserLoginState,
    setUserSignUpData,
    getSurveyValue,
    setNavigateValue,
    setNavigateIdentifier,
    setSignUpVerification,
    setAppointmentVerification,
    setSignUpNumber,
    setBasicInfoNav,
    setSkills,
    removeSkills
  }=authSlice.actions

 export const authReducer=authSlice.reducer