import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";



const courseSlice=createSlice({
    name:'course',
    initialState:{
        courseVideo:[],
        loading:false,
        videoSrc:''
    },
    reducers:{
        setCourseData:(state,action)=>{
            state.courseVideo=action.payload
        },
        setVideo:(state,action)=>{
            state.videoSrc=action.payload
        }
    }

})

export const {setCourseData,setVideo}=courseSlice.actions

export const courseReducer=courseSlice.reducer